import React, { useState, useEffect } from 'react'
import { Container, Col, Row } from 'reactstrap'
import axios from 'axios'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CareCard from '../../components/CareCard/careCard'

import LatestImage from '../../assets/graphics/graphic-latest.svg'

const LatestPage = () => {
	const [cardData, setCardData] = useState([])
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		async function getData() {
			try {
				const cardResponse = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/cpcs/latest/100`)

				setCardData(cardResponse.data.cpcs)
				setLoaded(true)
			} catch (error) {
				console.error('Error getting card data: ', error)
			}
		}
		getData()
	}, [])

	return (
		<Layout>
			<SEO title='Latest' />
			<Container>
				<Row className='my-5'>
					<Col md={2} className='text-center'>
						<img src={LatestImage} alt='' />
					</Col>
					<Col md={10} className='mb-3 px-5'>
						<Row className='latest-row-title'>The Latest</Row>
						<Row>
							<span className='latest-row-subtitle pt-2'>
								The world is full of gratitude for healthcare workers, so we suggest you check here daily to read about moments that
								will make you smile.
							</span>
						</Row>
					</Col>
				</Row>
				{loaded && (
					<Col>
						<Row className='p-3 justify-content-center'>
							{cardData.length ? (
								cardData.map((_, index) => {
									return (
										<Row key={index}>
											<div className='px-2'>
												<CareCard post={_} />
											</div>
										</Row>
									)
								})
							) : (
								<Row className='latest-no-results px-4 pb-3'>No cards found.</Row>
							)}
						</Row>
					</Col>
				)}
			</Container>
		</Layout>
	)
}

export default LatestPage
